import { API } from '@/api'
import { useAuth } from '@/providers/AuthProvider'
import Toast from '@/utils/Toast'
import NextLink from 'next/link'
import { useRouter, withRouter } from 'next/router'
import React, { Children } from 'react'
import { Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query'

const Link = withRouter(({ router, children, ...props }) => {
  const child = Children.only(children)

  let className = child.props.className || ''
  if (router.pathname === props.href && props.activeClassName) {
    className = `${className} ${props.activeClassName}`.trim()
  }

  delete props.activeClassName

  return (
    <NextLink {...props}>{React.cloneElement(child, { className })}</NextLink>
  )
})

const Navbar = ({ shouldGoOnTop = false }) => {
  const [menu, setMenu] = React.useState(true)
  const router = useRouter()

  const auth = useAuth()
  const toggleNavbar = () => {
    setMenu(!menu)
  }

  React.useEffect(() => {
    let elementId = document.getElementById('navbar')
    const handleScroll = () => {
      if (window.scrollY > 170) {
        elementId.classList.add('is-sticky')
      } else {
        elementId.classList.remove('is-sticky')
      }
    }
    if (shouldGoOnTop) {
      document.addEventListener('scroll', handleScroll)
      window.scrollTo(0, 0)
    }
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  })

  const handleLogout = () => {
    localStorage.removeItem('precise-mentorship')
    // localStorage.removeItem('browserToken')
    // auth.setPreviousPath('')

    auth.setUser({})
    router.push('/')
  }

  const handleAdminLogout = () => {
    localStorage.removeItem('precise-mentorship-admin')
    auth.setAdmin({ role: [] })
    router.push('/admin')
  }

  const { mutateAsync, isLoading } = useMutation({
    mutationKey: [API.STUDENTS.JOIN_AS_AFFILIATE.name],
    mutationFn: API.STUDENTS.JOIN_AS_AFFILIATE,
  })

  const handleJoinAffiliate = async () => {
    try {
      await mutateAsync()
      Toast('suc', 'Successfully Joined as Affiliate')
    } catch (e) {
      Toast('err', e.message || 'Something went wrong')
    }
  }

  const classOne = menu
    ? 'collapse navbar-collapse'
    : 'collapse navbar-collapse show'
  const classTwo = menu
    ? 'navbar-toggler navbar-toggler-right collapsed'
    : 'navbar-toggler navbar-toggler-right'

  return (
    <React.Fragment>
      <div id='navbar' className='navbar-area'>
        <div className='edemy-nav'>
          <div className='container-fluid px-4'>
            <div className='navbar navbar-expand-lg navbar-light'>
              <Link href='/'>
                <a
                  onClick={toggleNavbar}
                  className='navbar-brand  d-flex justify-content-center align-items-center'
                >
                  <img
                    src='/images/precise-mentorship-favicon.png'
                    alt='logo'
                    height='40px'
                    width='40px'
                  />
                  <div className='ms-1'>
                    <h5
                      style={{
                        color: '#fe4a55',
                        fontWeight: '900',
                        marginBottom: '0px',
                      }}
                    >
                      Precise
                    </h5>
                    <h4
                      style={{
                        color: '#221638',
                        fontWeight: '900',
                        marginBottom: '0px',
                      }}
                    >
                      Mentorship
                    </h4>
                  </div>
                </a>
              </Link>

              <button
                onClick={toggleNavbar}
                className={classTwo}
                type='button'
                data-toggle='collapse'
                data-target='#navbarSupportedContent'
                aria-controls='navbarSupportedContent'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <span className='icon-bar top-bar'></span>
                <span className='icon-bar middle-bar'></span>
                <span className='icon-bar bottom-bar'></span>
              </button>

              <div className={classOne} id='navbarSupportedContent'>
                <ul className='navbar-nav'>
                  <li className='nav-item'>
                    <Link href='/'>
                      <a className='nav-link'>Home</a>
                    </Link>
                  </li>

                  <li className='nav-item '>
                    <Link href='/courses'>
                      <a className='nav-link'>Courses</a>
                    </Link>
                  </li>
                </ul>

                <div className='others-option d-flex align-items-center'>
                  <div className='option-item'>
                    <div className='user-dropdown'></div>
                  </div>
                  {router?.pathname.slice(1, 6) !== 'admin' &&
                  router?.pathname !== 'admin-authentication' ? (
                    <div className='option-item'>
                      {auth?.user?._id ? (
                        <div className='user-dropdown'>
                          <Link href='/'>
                            <a
                              onClick={(e) => e.preventDefault()}
                              className='default-btn me-3 m-0'
                            >
                              <i className='flaticon-user'></i>{' '}
                              {auth?.user?.name}
                              <span></span>
                            </a>
                          </Link>

                          <ul className='dropdown-menu'>
                            <li className='nav-item'>
                              <Link href='/my-courses' activeClassName='active'>
                                <a onClick={toggleNavbar} className='nav-link'>
                                  My Courses
                                </a>
                              </Link>
                            </li>

                            <li className='nav-item'>
                              <Link
                                href='/user/my-profile'
                                activeClassName='active'
                              >
                                <a onClick={toggleNavbar} className='nav-link'>
                                  My Profile
                                </a>
                              </Link>
                            </li>
                            <li className='nav-item'>
                              <Link
                                href='/earning/my-earnings'
                                activeClassName='active'
                              >
                                <a onClick={toggleNavbar} className='nav-link'>
                                  My Earnings
                                </a>
                              </Link>
                            </li>

                            {!auth?.user?.affiliation_code && (
                              <li className='nav-item'>
                                <a
                                  onClick={handleJoinAffiliate}
                                  className='nav-link'
                                  role='button'
                                >
                                  Join as affiliate{' '}
                                  {isLoading && (
                                    <Spinner size='sm' animation='border' />
                                  )}
                                </a>
                              </li>
                            )}

                            <li className='nav-item'>
                              <Link href='/'>
                                <a
                                  className='nav-link'
                                  onClick={(e) => {
                                    e.preventDefault()
                                    handleLogout()
                                  }}
                                >
                                  Logout
                                </a>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <Link href='/profile-authentication'>
                          <a className='default-btn me-1'>
                            <i className='flaticon-user'></i> Login/Register{' '}
                            <span></span>
                          </a>
                        </Link>
                      )}
                    </div>
                  ) : (
                    <div className='option-item'>
                      {auth?.admin?._id ? (
                        <div className='user-dropdown'>
                          <Link href='/'>
                            <a
                              onClick={(e) => e.preventDefault()}
                              className='default-btn me-3'
                            >
                              <i className='flaticon-user'></i>{' '}
                              {auth?.admin?.name}
                              <span></span>
                            </a>
                          </Link>

                          <ul className='dropdown-menu'>
                            <li className='nav-item'>
                              <Link
                                href='/admin/admin-profile'
                                activeClassName='active'
                              >
                                <a onClick={toggleNavbar} className='nav-link'>
                                  My Profile
                                </a>
                              </Link>
                            </li>

                            <li className='nav-item'>
                              <Link href='/'>
                                <a
                                  className='nav-link'
                                  onClick={(e) => {
                                    e.preventDefault()
                                    handleAdminLogout()
                                  }}
                                >
                                  Logout
                                </a>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <Link href='/admin-authentication'>
                          <a className='default-btn'>
                            <i className='flaticon-user'></i> Login As Admin{' '}
                            <span></span>
                          </a>
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Navbar
