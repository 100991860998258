import { http } from '@/lib/axios'

export const CREATE_PROMOTION = (data) => {
  return http({
    method: 'POST',
    data: data,
    url: '/promotion/create',
  })
}

export const EDIT_PROMOTION = (data) => {
  return http({
    method: 'PUT',
    data: data,
    url: 'promotion/edit',
  })
}

export const GET_PROMOTION = ({ queryKey }) => {
  const [_, args] = queryKey
  return http({
    method: 'GET',
    params: args,
    url: '/promotion/get',
  })
}
