import { http2 } from '@/lib/axios'

export const GET_TEAMS = ({ queryKey }) => {
  const [_, args] = queryKey
  return http2({
    method: 'GET',
    params: args,
    url: `/team/get`,
  })
}

export const GET_MY_TEAMS = ({ queryKey }) => {
  const [_, args] = queryKey
  return http2({
    method: 'GET',
    params: args,
    url: `/team/my_teams`,
  })
}

export const CREATE_TEAM = (data) => {
  return http2({
    method: 'POST',
    data: data,
    url: `/team/create`,
  })
}
export const EDIT_TEAM = (data) => {
  return http2({
    method: 'PUT',
    data: data,
    url: `/team/edit`,
  })
}

export const GET_TEAM_DETAILS = ({ queryKey}) => {
  const [_, args] = queryKey
  return http2({
    method: "GET",
    params: args,
    url: `/team/team_details`,
  })
}
