import { API } from '@/api'
import { useAuth } from '@/providers/AuthProvider'
import styles from '@/styles/footer.module.css'
import Toast from '@/utils/Toast'
import Link from 'next/link'
import { useMutation } from 'react-query'

const Footer = () => {
  const currentYear = new Date().getFullYear()
  const { mutateAsync, isLoading } = useMutation({
    mutationKey: [API.STUDENTS.JOIN_AS_AFFILIATE.name],
    mutationFn: API.STUDENTS.JOIN_AS_AFFILIATE,
  })

  const handleJoinAffiliate = async () => {
    try {
      await mutateAsync()
      Toast('suc', 'Successfully Joined as Affiliate')
    } catch (e) {
      Toast('err', e.message || 'Something went wrong')
    }
  }

  const { user, isUserLoading } = useAuth()
  const isLoggedIn = !isUserLoading && Object.keys(user).length > 0

  return (
    <footer className='footer-area'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4 col-md-6 col-sm-6'>
            <div className='single-footer-widget'>
              <Link href='/'>
                <a className='logo d-flex justify-content-start align-items-center'>
                  <img
                    src='/images/precise-mentorship-favicon.png'
                    alt='logo'
                    height='40px'
                    width='40px'
                  />
                  <div className='ms-1'>
                    <h5
                      style={{
                        color: '#fe4a55',
                        fontWeight: '900',
                        marginBottom: '0px',
                      }}
                    >
                      Precise
                    </h5>
                    <h4
                      style={{
                        color: 'white',
                        fontWeight: '900',
                        marginBottom: '0px',
                      }}
                    >
                      Mentorship
                    </h4>
                  </div>
                </a>
              </Link>

              <p>
                Precise Mentorship - Your Mentor to Guide you Precisely to
                Achieve your Dream
              </p>

              <ul className='social-link'>
                <li>
                  <a
                    href='https://www.facebook.com/precisementorshipbd'
                    className='d-block'
                    target='_blank'
                  >
                    <i className='bx bxl-facebook'></i>
                  </a>
                </li>
                <li>
                  <a
                    href='https://youtube.com/channel/UCtKBzP-PdexjyvK7xk_Lulg'
                    className='d-block'
                    target='_blank'
                  >
                    <i className='bx bxl-youtube'></i>
                  </a>
                </li>
                <li>
                  <a href='#' className='d-block' target='_blank'>
                    <i className='bx bxl-instagram'></i>
                  </a>
                </li>
                <li>
                  <a href='#' className='d-block' target='_blank'>
                    <i className='bx bxl-linkedin'></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className='col-lg-2 col-md-6 col-sm-6'>
            <div className='single-footer-widget pl-5'>
              <h3>Explore</h3>
              <ul className='footer-links-list'>
                <li>
                  <Link href='/'>
                    <a>Home</a>
                  </Link>
                </li>
                <li>
                  <Link href='/refund-policy'>
                    <a>Refund Policy</a>
                  </Link>
                </li>

                <li>
                  <Link href='/courses'>
                    <a>Courses</a>
                  </Link>
                </li>

                {isLoggedIn && (
                  <>
                    <li>
                      <button
                        disabled={isLoading}
                        className={styles['btn-ghost']}
                        onClick={handleJoinAffiliate}
                      >
                        Join as Affiliate
                      </button>
                    </li>

                    <li>
                      <Link href='/earning/my-earnings'>My Earnings</Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>

          <div className='col-lg-6 col-md-8 col-sm-8'>
            <div className='single-footer-widget'>
              <h3>Address</h3>
              <ul className='footer-contact-info'>
                <li>
                  <i className='bx bx-map'></i>
                  Dhaka-1000
                </li>

                <li>
                  <i className='bx bx-envelope'></i>
                  <a href='mailto:precisementorship@gmail.com'>
                    precisementorship@gmail.com
                  </a>
                </li>
                <li
                  className='bg-white  m-0 p-0 mt-3'
                  style={{
                    height: '60px',
                    width: '60px',
                    borderRadius: '30%',
                  }}
                >
                  <a
                    href='https://www.facebook.com/precisementorshipbd'
                    className=''
                    target='_blank'
                  >
                    <img
                      src='/images/Facebook.svg'
                      alt='fb-icon'
                      height='60px'
                      width='60px'
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className='footer-bottom-area'>
          <div className='row align-items-center'>
            <div className='col-lg-6 col-md-6'>
              <p>
                <i className='bx bx-copyright'></i>
                {currentYear} PRECISEMENTORSHIP. ALL RIGHTS RESERVED.
              </p>
            </div>

            <div className='col-lg-6 col-md-6'></div>
          </div>
        </div>
      </div>

      <div className='lines'>
        <div className='line'></div>
        <div className='line'></div>
        <div className='line'></div>
      </div>
    </footer>
  )
}

export default Footer
