import { http2 } from '@/lib/axios'

export const CREATE_PAYMENT = (values) => {
  return http2({
    method: 'POST',
    data: values,
    url: '/payment/create',
  })
}

export const GET_DISCOUNTS = ({ queryKey }) => {
  const [_, args] = queryKey
  return http2({
    method: 'GET',
    params: args,
    url: '/payment/locked',
  })
}

export const GENERATE_DISCOUNT_LOCKING_LINK = (data) => {
  return http2({
    method: 'POST',
    data,
    url: '/payment/generate_discount_locking_link',
  })
}
