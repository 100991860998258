import { http, http2 } from '@/lib/axios'

export const GET_COURSES = ({ queryKey }) => {
  const [_, args] = queryKey
  return http({
    method: 'GET',
    params: args,
    url: '/course/get',
  })
}

export const GET_COURSES_2 = ({ queryKey }) => {
  const [_, args] = queryKey
  return http2({
    method: 'GET',
    params: args,
    url: '/course/get',
  })
}

export const GET_COURSE_FILTERS = ({ queryKey }) => {
  const [_, args] = queryKey
  return http({
    method: 'GET',
    params: args,
    url: '/course/get_all_filter',
  })
}

export const CREATE_COURSE = (values) => {
  return http({
    method: 'POST',
    data: values,
    url: '/course/create',
  })
}

export const UPDATE_COURSE = (values) => {
  return http({
    method: 'PUT',
    data: values,
    url: '/course/edit',
  })
}

export const DELETE_COURSE = (id) => {
  return http({
    method: 'DELETE',
    url: `/course/delete?_id=${id}`,
  })
}

export const DUPLICATE_COURSE = (values) => {
  return http({
    method: 'GET',
    params: values,
    url: '/course/duplicate',
  })
}
export const GET_PROMOTIONAL_LINK = ({ queryKey }) => {
  const [_, args] = queryKey
  return http2({
    method: 'GET',
    params: args,
    url: '/course/get_promotional_link',
  })
}

export const GET_COURSE_TAGS = () => {
  return http({
    method: 'GET',
    url: '/course/get_all_filter',
  })
}
