import * as ADMINS from '@/api/admin'
import * as CONTENTS from '@/api/contents'
import * as COURSES from '@/api/courses'
import * as EARNING from '@/api/earning'
import * as MISC from '@/api/misc'
import * as MODULES from '@/api/modules'
import * as ORDERS from '@/api/orders'
import * as PAYMENT from '@/api/payment'
import * as PROMOTION from '@/api/promotion'
import * as QUESTIONS from '@/api/questions'
import * as STUDENTS from '@/api/students'
import * as TEAM from '@/api/team'

export const API = {
  COURSES,
  MODULES,
  CONTENTS,
  QUESTIONS,
  ORDERS,
  STUDENTS,
  ADMINS,
  MISC,
  PAYMENT,
  PROMOTION,
  EARNING,
  TEAM,
}
