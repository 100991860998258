import { http2 } from '@/lib/axios'

export const GET_EARNINGS = ({ queryKey }) => {
  const [_, args] = queryKey
  return http2({
    method: 'GET',
    params: args,
    url: '/earning/my_earnings',
  })
}

export const GET_MY_RANKING = ({ queryKey }) => {
  const [_, args] = queryKey
  return http2({
    method: 'GET',
    params: args,
    url: '/earning/my-ranking',
  })
}

export const REQUEST_PAYMENT = (data) => {
  return http2({
    method: 'POST',
    data,
    url: '/earning/request-payment',
  })
}

export const GET_MY_WITHDRAWALS = ({ queryKey }) => {
  const [_, args] = queryKey
  return http2({
    method: 'GET',
    params: args,
    url: '/earning/my_withdrawals',
  })
}