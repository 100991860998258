import { http, http2 } from '@/lib/axios'

export const GET_MODULES = ({ queryKey }) => {
	const [_, args] = queryKey
	return http({
		method: 'GET',
		params: args,
		url: '/module/get',
	})
}

export const CREATE_MODULE = (values) => {
	return http({
		method: 'POST',
		data: values,
		url: '/module/create',
	})
}

export const UPDATE_MODULE = (values) => {
	return http({
		method: 'PUT',
		data: values,
		url: '/module/edit',
	})
}

export const DELETE_MODULE = (id) => {
	return http({
		method: 'DELETE',
		url: `/module/delete?_id=${id}`,
	})
}

export const GET_FULL_COURSE_TREE = ({ queryKey }) => {
  const [_, args] = queryKey
  return http({
    method: 'GET',
    params: args,
    url: '/full_course/get_full_course_tree',
  })
}

export const GET_FULL_COURSE_TREE_STUDENT = ({ queryKey }) => {
  const [_, args] = queryKey
  return http2({
    method: 'GET',
    params: args,
    url: '/full_course/get_full_course_tree',
  })
}
